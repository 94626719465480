import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/flight/footer1.png";
import { phoneNum } from "../../utils/globalVars";
import "./Footer.styles.css";
import moment from "moment";
import { useState } from "react";

const Footer = () => {
  const [isActive, setActive] = useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleExpClick = (origin, dest) => {
    navigate({
      pathname: "/flights",
      search: `?search_t=${moment().unix()}&tripType=one-way&dep_loc=${origin}&dest_loc=${dest}&dep_dt=${moment().format(
        "YYYY-MM-DD"
      )}&ret_dt=null&fl_cl=ECONOMY&adt=1&chd=0`,
    });
  };

  return (
    <footer className="footer-area">
      <div className="container">
        <div className="main-footer">
          <div className="col-md-3 col-sm-6">
            <div className="single-footer">
              <div className="footer-title">
                <Link href="/">
                  <img
                    className="logo-img"
                    src={require("../../assets/images/home/logo2.png")}
                    alt=""
                  />
                </Link>
              </div>
              <div className="footer-left">
                <div className="footer-logo"></div>
                <ul className="footer-contact">
                  <li>
                    <img
                      className="map"
                      src={require("../../assets/images/home/map.png")}
                      alt=""
                    />
                      30 N Gould St STE ST R<br/>
                      Sheridan, WY 82801
                  </li>
                  <li>
                    <img
                      className="map"
                      src={require("../../assets/images/home/phone.png")}
                      alt=""
                    />
                      <a href={`tel:${phoneNum.value}`}>{phoneNum.label}</a>

                  </li>
                  <li>
                    <img
                      className="map"
                      src={require("../../assets/images/home/gmail.png")}
                      alt=""
                    />
                    info@flybirdtrips.com
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="single-footer">
              <div className="single-recent-post">
                <div className="footer-title">
                  <h3>TERMS</h3>
                </div>
                <ul className="recent-post">
                  <li>
                    <Link to="/about-us">
                      <div className="post-content">
                        <p>About us</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us">
                      <div className="post-content">
                        <p>Contact us</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      <div className="post-content">
                        <p>Privacy Policy</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-and-conditions">
                      <div className="post-content">
                        <p>Terms & Conditions</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/disclaimer">
                      <div className="post-content">
                        <p>Disclaimer</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/Refund-and-cancellation-Policy">
                      <div className="post-content">
                        <p>Refund and Cancellation Policy</p>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="single-footer">
              <div className="footer-title">
                <h3>Destination</h3>
              </div>
              <ul className="footer-gallery">
                <li>
                  <a href="#">
                    <div className="image-overlay">
                      <img
                        src={require("../../assets/images/home/des-1.jpg")}
                        alt=""
                      />
                      <div className="overly-city">
                        <span>Austrila</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div className="image-overlay">
                      <img
                        src={require("../../assets/images/home/des-2.jpg")}
                        alt=""
                      />
                      <div className="overly-city">
                        <span>England</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div className="image-overlay">
                      <img
                        src={require("../../assets/images/home/des-3.jpg")}
                        alt=""
                      />
                      <div className="overly-city">
                        <span>France</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div className="image-overlay">
                      <img
                        src={require("../../assets/images/home/des-4.jpg")}
                        alt=""
                      />
                      <div className="overly-city">
                        <span>America</span>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 f-phone-responsive">
            <div className="single-footer">
              <div className="footer-title">
                <h3>Quick Contact</h3>
              </div>
              <div className="footer-contact-form">
                <form action="#">
                  <ul className="footer-form-element">
                    <li className="place-col">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Email Address"
                        onblur="if(this.value==''){this.value='Email Address'}"
                        onfocus="if(this.value=='Email Address'){this.value=''}"
                      />
                    </li>
                    <li>
                      <textarea
                        name="message"
                        id="message"
                        cols="30"
                        rows="10"
                        placeholder="Message"
                      ></textarea>
                    </li>
                    <li>
                      <button>Send</button>
                    </li>
                  </ul>
                </form>
              </div>
              <div className="footer-social-media">
                <div className="social-footer-title">
                  <h3>Follow Us</h3>
                </div>
                <ul className="footer-social-link">
                  <li className="facebook">
                    <a href="#">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li className="twitter">
                    <a href="#">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li className="linkedin">
                    <a href="#">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li className="gplus">
                    <a href="#">
                      <i className="fa fa-google-plus"></i>
                    </a>
                  </li>
                  <li className="youtube">
                    <a href="#">
                      <i className="fa fa-youtube-play"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="footer-bottom">
            <div className="col-md-5">
              <div className="copyright">
                <p>Copyright © 2023 flybirdtrips</p>
              </div>
            </div>
            <div className="col-md-7">
              <ul className="payicon pull-right">
                <li>We Accept</li>
                <li>
                  <img
                    src={require("../../assets/images/home/visa.png")}
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src={require("../../assets/images/home/paypal.png")}
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src={require("../../assets/images/home/amex.png")}
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src={require("../../assets/images/home/master.png")}
                    alt=""
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
