import React, { useState } from "react";
import "./CtaPopup.styles.css";

import calling from "../../assets/images/flight/calling.png";

import logo from "../../assets/images/flight/9024.png";
import { phoneNum } from "../../utils/globalVars";

const CtaPopup = ({ dataToSend }) => {
  const [ctaPopStatus, setCtaPopStatus] = React.useState(true);

  const closePopup = ()=>{
    setCtaPopStatus(false)
  }

  return (
    <div
      className={`cm-cta-popup-container cm-flex-type-2 ${
        ctaPopStatus ? "cm-cta-popup-active" : ""
      }`}
    >
      <div className="cm-cta-pop-wrapper cm-white-bg cm-pos-relative cm-txt-center">
        <div className="cm-pop-logo">
          <img  src={logo} alt="Fly Bird Trips" />
        </div>
        <div
          className="cm-close cm-flex-type-2 cm-sec-bg2 cm-white-col cm-pointer"
          onClick={closePopup}
        >
          <i className="fa-solid fa-xmark"></i>
        </div>
        <h1 className="">Looking for the best deals on </h1>
        <h2>
          <span className="color-sec">{dataToSend.locationDeparture}</span> to{" "}
          <span className="color-sec">{dataToSend.locationArrival}</span>?
        </h2>
        <h3>
            <a className="color-sec" href={`tel:${phoneNum.value}`}>
              {phoneNum.label}
            </a>
          </h3>
        <div className="popper-image">
          <img src={calling}/>
        </div>
        
        
        <div className="cm-content cm-new-popper">
          <a
            className="btn"
            href={`tel:${phoneNum.value}`}
          >
            Call Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default CtaPopup;
