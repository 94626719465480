import React from "react";
import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import FlightSearchForm from "../../components/flight-search-form/FlightSearchForm.component";
import moment from "moment";
import BannerSearch from "../../components/Homebanner/BannerSearch";
import "./Homepage.styles.css";
import { useNavigate } from "react-router-dom";
import { phoneNum } from "../../utils/globalVars";

import bgImgse from "../../app/images/Rectangle 2.jpg";
import serviceImg from "../../app/images/services-background.png";
import testiImg from "../../app/images/testimonials-back.png";

const HomePage = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleExpClick = (origin, dest) => {
    navigate({
      pathname: "/flights",
      search: `?search_t=${moment().unix()}&tripType=one-way&dep_loc=${origin}&dest_loc=${dest}&dep_dt=${moment().format(
        "YYYY-MM-DD"
      )}&ret_dt=null&fl_cl=ECONOMY&adt=1&chd=0`,
    });
  };

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <React.Fragment>
      {/*<div
        className="banner-section bg-layer"
        style={{ backgroundImage: `url('${bgImgse}')` }}
      >
        <div className="container">
          <div className="grid-2 align-center">
            <div className="column-40">
              <h4>Planning a trip?</h4>
              <h1>Book your travel at the lowest air fare</h1>
              <p>Easily compare and book your next flight</p>
            </div>
            <div className="column-60">
              <BannerSearch />
            </div>
          </div>
        </div>
      </div>

      <div className="offer-section">
        <div className="container">
          <h4>Choose your place</h4>
          <h2>
            Popular <span>Tours</span>
          </h2>
        </div>
        <div className="container">
          <div className="tours-list">
            <div
              className="tours-inner-50"
              onClick={(e) => handleExpClick("MLE", "JFK")}
            >
              <a href="#">
                <img src="./images/maldives.png" alt="" />
                <p>Maldives Tour</p>
              </a>
            </div>
            <div
              className="tours-inner-25"
              onClick={(e) => handleExpClick("MXP", "LHR")}
            >
              <a href="#">
                <img src="./images/rome.png" alt="" />
                <p>Rome</p>
              </a>
            </div>
            <div
              className="tours-inner-25"
              onClick={(e) => handleExpClick("NCE", "MXP")}
            >
              <a href="#">
                <img src="./images/france.png" alt="" />
                <p>France</p>
              </a>
            </div>
            <div
              className="tours-inner-25"
              onClick={(e) => handleExpClick("NVT", "MXP")}
            >
              <a href="#">
                <img src="./images/brazil.png" alt="" />
                <p>Brazil</p>
              </a>
            </div>
            <div
              className="tours-inner-25"
              onClick={(e) => handleExpClick("YXC", "DEL")}
            >
              <a href="#">
                <img src="./images/canada.png" alt="" />
                <p>Canada</p>
              </a>
            </div>
            <div
              className="tours-inner-50"
              onClick={(e) => handleExpClick("HBE", "BOS")}
            >
              <a href="#">
                <img src="./images/egypt.png" alt="" />
                <p>Egypt Tour</p>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className="services-section"
        style={{ backgroundImage: `url('${serviceImg}')` }}
      >
        <div className="container">
          <div className="services-offer">
            <div className="offered-services">
              <div className="service-img">
                <img src="./images/plane.png" alt="" />
              </div>
              <div className="arrow-img1">
                <img src="./images/arrow.png" alt="" />
              </div>
              <div className="services-text">
                <h3>768</h3>
                <p>Flight Booking</p>
              </div>
            </div>

            <div className="offered-services">
              <div className="service-img">
                <img src="./images/location.png" alt="" />
              </div>
              <div className="arrow-img2 shimmer">
                <img
                  className="stroke animate"
                  src="./images/arrow2.png"
                  alt=""
                />
              </div>
              <div className="services-text">
                <h3>6072</h3>
                <p>Distance Covered</p>
              </div>
            </div>
            <div className="offered-services">
              <div className="service-img">
                <img src="./images/happy.png" alt="" />
              </div>
              <div className="arrow-img3">
                <img src="./images/arrow.png" alt="" />
              </div>
              <div className="services-text">
                <h3>693</h3>
                <p>Happy Customers</p>
              </div>
            </div>
            <div className="offered-services">
              <div className="service-img">
                <img src="./images/dollar.png" alt="" />
              </div>
              <div className="services-text">
                <h3>10978</h3>
                <p>Dollar Saved</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="offer-section minute-offer">
        <div className="container">
          <h4>Top Destination</h4>
          <h2>
            Popular <span>Destination</span>
          </h2>
        </div>
        <div className="container">
          <div className="" id="minuteOffer">
            <Slider {...sliderSettings}>
              <div>
                <div className="column">
                  <div
                    className="col-inner"
                    onClick={(e) => handleExpClick("BAQ", "ABJ")}
                  >
                    <a href="#" className="image-box">
                      <img src="images/colorado-1.png" alt="" />
                      <p>Colombia</p>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="column">
                  <div
                    className="col-inner"
                    onClick={(e) => handleExpClick("RIC", "BOS")}
                  >
                    <a href="#" className="image-box">
                      <img src="images/verginia-1.png" alt="" />
                      <p>Verginia</p>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="column">
                  <div
                    className="col-inner"
                    onClick={(e) => handleExpClick("AGP", "ABJ")}
                  >
                    <a href="#" className="image-box">
                      <img src="images/spain.png" alt="" />
                      <p>Spain</p>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="column">
                  <div
                    className="col-inner"
                    onClick={(e) => handleExpClick("HND", "ABV")}
                  >
                    <a href="#" className="image-box">
                      <img src="images/Japan.png" alt="" />
                      <p>Japan</p>
                    </a>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <div className="testimonials-section">
        <div className="container grid-2">
          <div className="testi-head column">
            <h2>
              We Provide Best Deal <br /> Book now & Enjoy...{" "}
            </h2>
            <p>
              <i className="fa fa-phone" aria-hidden="true"></i>+1-207-907-5354
            </p>
          </div>
          <div className="testi-slider column">
            <div className="testi-slide">
              <div className="slider-text">
                <h4>TESTIMONIALS</h4>
                <h2>
                  Travelers <span>Rating</span>
                </h2>
              </div>
              <div id="tetimonials-sliders">
                <Slider {...settings}>
                  <div className="slider-content">
                    <p>
                      I recently used flybirdtripsto book my flight, and I couldn't
                      be happier with the experience. The website's
                      user-friendly interface made the whole process a breeze. I
                      quickly found the best flight options for my trip, and the
                      competitive pricing saved me some extra bucks.
                    </p>
                    <div className="customer-details">
                      <div className="customer-image">
                        <img src="./images/customer.png" alt="" />
                      </div>
                      <div className="customer-name">
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <h5>Katherin West</h5>
                      </div>
                    </div>
                  </div>
                  <div className="slider-content">
                    <p>
                      I recently used flybirdtripsto book my flight, and I couldn't
                      be happier with the experience. The website's
                      user-friendly interface made the whole process a breeze. I
                      quickly found the best flight options for my trip, and the
                      competitive pricing saved me some extra bucks.
                    </p>
                    <div className="customer-details">
                      <div className="customer-image">
                        <img src="./images/customer.png" alt="" />
                      </div>
                      <div className="customer-name">
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <h5>Katherin West</h5>
                      </div>
                    </div>
                  </div>
                  <div className="slider-content">
                    <p>
                      I recently used flybirdtripsto book my flight, and I couldn't
                      be happier with the experience. The website's
                      user-friendly interface made the whole process a breeze. I
                      quickly found the best flight options for my trip, and the
                      competitive pricing saved me some extra bucks.
                    </p>
                    <div className="customer-details">
                      <div className="customer-image">
                        <img src="./images/customer.png" alt="" />
                      </div>
                      <div className="customer-name">
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <i className="fa fa-star" aria-hidden="true"></i>
                        <h5>Katherin West</h5>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
  </div>*/}

      <section className="tabbased-search">
        <div className="cm-page-center">
          <div className="search-banner">
            <BannerSearch />
          </div>
        </div>
      </section>

      <section className="popular-packages padding-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-title text-center">
                <h2>Our most popular packges</h2>
                <p>Checkout these amzaing packages at extremely low prices</p>
              </div>
            </div>
          </div>
          <div className="row packages">
            <div className="col-md-4 col-sm-6">
              <div className="single-package">
                <div className="package-image">
                  <a href="#">
                    <img
                      src={require("../../assets/images/home/dubai.jpg")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="package-content">
                  <h3>Dubai – All Stunning Places</h3>
                  <p>
                    4 Days, 5 Nights Start From <span>$500</span>
                  </p>
                </div>
                <div className="package-calto-action">
                  <ul className="ct-action">
                    <li onClick={(e) => handleExpClick("DXB", "BOS")}>
                      <a
                        href="#"
                        className="travel-booking-btn hvr-shutter-out-horizontal"
                      >
                        Book Now
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="single-package">
                <div className="package-image">
                  <a href="#">
                    <img
                      src={require("../../assets/images/home/thailand.jpg")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="package-content">
                  <h3>Thailand – All Stunning Places</h3>
                  <p>
                    4 Days, 5 Nights Start From <span>$500</span>
                  </p>
                </div>
                <div className="package-calto-action">
                  <ul className="ct-action">
                    <li onClick={(e) => handleExpClick("UTH", "BOS")}>
                      <a
                        href="#"
                        className="travel-booking-btn hvr-shutter-out-horizontal"
                      >
                        Book Now
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="single-package">
                <div className="package-image">
                  <a href="#">
                    <img
                      src={require("../../assets/images/home/england.jpg")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="package-content">
                  <h3>England – All Stunning Places</h3>
                  <p>
                    4 Days, 5 Nights Start From <span>$500</span>
                  </p>
                </div>
                <div className="package-calto-action">
                  <ul className="ct-action">
                    <li onClick={(e) => handleExpClick("	BRS", "NYC")}>
                      <a
                        href="#"
                        className="travel-booking-btn hvr-shutter-out-horizontal"
                      >
                        Book Now
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="single-package">
                <div className="package-image">
                  <a href="#">
                    <img
                      src={require("../../assets/images/home/italy.jpg")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="package-content">
                  <h3>Italy – All Stunning Places</h3>
                  <p>
                    4 Days, 5 Nights Start From <span>$500</span>
                  </p>
                </div>
                <div className="package-calto-action">
                  <ul className="ct-action">
                    <li onClick={(e) => handleExpClick("HND", "ABV")}>
                      <a
                        href="#"
                        className="travel-booking-btn hvr-shutter-out-horizontal"
                      >
                        Book Now
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="single-package">
                <div className="package-image">
                  <a href="#">
                    <img
                      src={require("../../assets/images/home/brazil.jpg")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="package-content">
                  <h3>Brazil – All Stunning Places</h3>
                  <p>
                    4 Days, 5 Nights Start From <span>$500</span>
                  </p>
                </div>
                <div className="package-calto-action">
                  <ul className="ct-action">
                    <li onClick={(e) => handleExpClick("GRU", "ABV")}>
                      <a
                        href="#"
                        className="travel-booking-btn hvr-shutter-out-horizontal"
                      >
                        Book Now
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="single-package">
                <div className="package-image">
                  <a href="#">
                    <img
                      src={require("../../assets/images/home/india.jpg")}
                      alt=""
                    />
                  </a>
                </div>
                <div className="package-content">
                  <h3>India – All Stunning Places</h3>
                  <p>
                    4 Days, 5 Nights Start From <span>$500</span>
                  </p>
                </div>
                <div className="package-calto-action">
                  <ul className="ct-action">
                    <li onClick={(e) => handleExpClick("HND", "ABV")}>
                      <a
                        href="#"
                        className="travel-booking-btn hvr-shutter-out-horizontal"
                      >
                        Book Now
                      </a>
                    </li>
                    <li>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="trabble-bg padding-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-title-white text-center">
                <h2>Why Choose flybirdtrips</h2>
                <p></p>
              </div>
            </div>
          </div>
          <div className="row why-choose">
            <div className="col-md-4 col-sm-6">
              <div className="single-travel">
                <div className="media">
                  <div className="media-left media-middle travel-number">
                    <span>01.</span>
                  </div>
                  <div className="media-body travel-content">
                    <h4>Travel Arrangements</h4>
                    <p>
                      {/* Lorem ipsum dolor sit amet consect adiu piscing elit sed
diam nonum euismo tincidunt ut.*/}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="single-travel">
                <div className="media">
                  <div className="media-left media-middle travel-number">
                    <span>02.</span>
                  </div>
                  <div className="media-body travel-content">
                    <h4>Cheap Flights</h4>
                    <p>
                      {/* Lorem ipsum dolor sit amet consect adiu piscing elit sed
diam nonum euismo tincidunt ut.*/}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="single-travel">
                <div className="media">
                  <div className="media-left media-middle travel-number">
                    <span>03.</span>
                  </div>
                  <div className="media-body travel-content">
                    <h4>Hand-picked tours</h4>
                    <p>
                      {/* Lorem ipsum dolor sit amet consect adiu piscing elit sed
diam nonum euismo tincidunt ut.*/}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="single-travel">
                <div className="media">
                  <div className="media-left media-middle travel-number">
                    <span>04.</span>
                  </div>
                  <div className="media-body travel-content">
                    <h4>Privet Guide</h4>
                    <p>
                      {/* Lorem ipsum dolor sit amet consect adiu piscing elit sed
diam nonum euismo tincidunt ut.*/}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="single-travel">
                <div className="media">
                  <div className="media-left media-middle travel-number">
                    <span>05.</span>
                  </div>
                  <div className="media-body travel-content">
                    <h4>Special Activities</h4>
                    <p>
                      {/* Lorem ipsum dolor sit amet consect adiu piscing elit sed
diam nonum euismo tincidunt ut.*/}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="single-travel">
                <div className="media">
                  <div className="media-left media-middle travel-number">
                    <span>06.</span>
                  </div>
                  <div className="media-body travel-content">
                    <h4>Best Price Guarantee</h4>
                    <p>
                      {/* Lorem ipsum dolor sit amet consect adiu piscing elit sed
diam nonum euismo tincidunt ut.*/}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="padding-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="section-title text-center">
                <h2>Most popular destination</h2>
                <p>Checkout our most popular destinations</p>
              </div>
            </div>
          </div>
          <div className="destination-slider">
            <div className="pop-destinations">
              <figure>
                <img
                  src={require("../../assets/images/home/australia.jpg")}
                  alt=""
                />
                <figcaption>
                  <a onClick={(e) => handleExpClick("SYD", "ABV")}
                    href="#"
                    className="travel-booking-btn hvr-shutter-out-horizontal"
                  >
                    Explore More
                  </a>
                </figcaption>
              </figure>
              <div className="destination-title">
                <a href="#">
                  <i className="fa fa-location-dot"></i>Sydney, Australia
                </a>
                <h4>
                  Opera House <span>3 Tours</span>
                </h4>
              </div>
            </div>
            <div className="pop-destinations">
              <figure>
                <img
                  src={require("../../assets/images/home/london.jpg")}
                  alt=""
                />
                <figcaption>
                  <a onClick={(e) => handleExpClick("LON", "BOS")}
                    href="#"
                    className="travel-booking-btn hvr-shutter-out-horizontal"
                  >
                    Explore More
                  </a>
                </figcaption>
              </figure>
              <div className="destination-title">
                <a href="#">
                  <i className="fa fa-location-dot"></i>London, England
                </a>
                <h4>
                  Tower Bridge <span>5 Tours</span>
                </h4>
              </div>
            </div>
            <div className="pop-destinations">
              <figure>
                <img
                  src={require("../../assets/images/home/paris.jpg")}
                  alt=""
                />
                <figcaption>
                  <a onClick={(e) => handleExpClick("PAR", "ABV")}
                    href="#"
                    className="travel-booking-btn hvr-shutter-out-horizontal"
                  >
                    Explore More
                  </a>
                </figcaption>
              </figure>
              <div className="destination-title">
                <a href="#">
                  <i className="fa fa-location-dot"></i>Paris, France
                </a>
                <h4>
                  Eiffel Tower <span>4 Tours</span>
                </h4>
              </div>
            </div>
            <div className="pop-destinations">
              <figure>
                <img
                  src={require("../../assets/images/home/new-york.jpg")}
                  alt=""
                />
                <figcaption>
                  <a
                    href="#"
                    className="travel-booking-btn hvr-shutter-out-horizontal"
                  >
                    Explore More
                  </a>
                </figcaption>
              </figure>
              <div className="destination-title">
                <a href="#" onClick={(e) => handleExpClick("NYC", "BOS")}>
                  <i className="fa fa-location-dot"></i>New York, USA
                </a>
                <h4>
                  Statue of Liberty <span>3 Tours</span>
                </h4>
              </div>
            </div>
            <div className="pop-destinations">
              <figure>
                <img
                  src={require("../../assets/images/home/agra.jpg")}
                  alt=""
                />
                <figcaption>
                  <a
                    href="#"
                    className="travel-booking-btn hvr-shutter-out-horizontal"
                  >
                    Explore More
                  </a>
                </figcaption>
              </figure>
              <div className="destination-title">
                <a href="#" onClick={(e) => handleExpClick("DEL", "NYC")}>
                  <i className="fa fa-location-dot"></i>Agra, India
                </a>
                <h4>
                  Tajmahal <span>5 Tours</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial-area padding-section">
        <div className="testimonial-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="section-title-white text-center mbt-100">
                  <h2>What travellers Say About Us</h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="text-center">
                <div className="top-testimonial-image">
                  <Slider {...sliderSettings}>
                    <div className="clients-review">
                      <img
                        src={require("../../assets/images/home/1.jpg")}
                        alt=""
                      />
                      <div className="testimonial-message">
                        <div className=" message">
                          <p>
                            I recently used flybirdtrips to book my flight, and
                            I couldn't be happier with the experience. The
                            website's user-friendly interface made the whole
                            process a breeze. I quickly found the best flight
                            options for my trip, and the competitive pricing
                            saved me some extra bucks.
                          </p>
                        </div>
                        <div className="rating">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                        <div className="client-bio">
                          <h4>Jhonthan Smith</h4>
                          <span>London Trip Travelers</span>
                        </div>
                      </div>
                    </div>
                    <div className="clients-review">
                      <img
                        src={require("../../assets/images/home/2.jpg")}
                        alt=""
                      />
                      <div className="testimonial-message">
                        <div className=" message">
                          <p>
                            I recently used flybirdtrips to book my flight, and
                            I couldn't be happier with the experience. The
                            website's user-friendly interface made the whole
                            process a breeze. I quickly found the best flight
                            options for my trip, and the competitive pricing
                            saved me some extra bucks.
                          </p>
                        </div>
                        <div className="rating">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                        <div className="client-bio">
                          <h4>Jhonthan Smith</h4>
                          <span>London Trip Travelers</span>
                        </div>
                      </div>
                    </div>
                    <div className="clients-review">
                      <img
                        src={require("../../assets/images/home/2.jpg")}
                        alt=""
                      />
                      <div className="testimonial-message">
                        <div className=" message">
                          <p>
                            I recently used flybirdtrips to book my flight, and
                            I couldn't be happier with the experience. The
                            website's user-friendly interface made the whole
                            process a breeze. I quickly found the best flight
                            options for my trip, and the competitive pricing
                            saved me some extra bucks.
                          </p>
                        </div>
                        <div className="rating">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                        <div className="client-bio">
                          <h4>Jhonthan Smith</h4>
                          <span>London Trip Travelers</span>
                        </div>
                      </div>
                    </div>
                    <div className="clients-review">
                      <img
                        src={require("../../assets/images/home/2.jpg")}
                        alt=""
                      />
                      <div className="testimonial-message">
                        <div className=" message">
                          <p>
                            I recently used flybirdtrips to book my flight, and
                            I couldn't be happier with the experience. The
                            website's user-friendly interface made the whole
                            process a breeze. I quickly found the best flight
                            options for my trip, and the competitive pricing
                            saved me some extra bucks.
                          </p>
                        </div>
                        <div className="rating">
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                          <i className="fa fa-star"></i>
                        </div>
                        <div className="client-bio">
                          <h4>Jhonthan Smith</h4>
                          <span>London Trip Travelers</span>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default HomePage;
