export const countryDeta = {
    au: {countryName:'Australia', currencyCode: "AUD", currencySign: ""},
    ca: {countryName:'Canada', currencyCode: "CAD", currencySign: ""},
    hk: {countryName:'Hong Kon', currencyCode: 'HKD', currencySign: ""},
    in: {countryName:'India', currencyCode:'INR', currencySign: ""},
    id: {countryName:'Indonesia', currencyCode:'IDR', currencySign: ""},
    my: {countryName:'Malaysia', currencyCode:'MYR', currencySign: ""},
    nz: {countryName:'New Zealand', currencyCode:'NZD', currencySign: ""},
    ph: {countryName:'Philippines', currencyCode:'PHP', currencySign: "₱"},
    qa: {countryName:'Qatar', currencyCode:'QAR', currencySign: ""},
    sg: {countryName:'Singapore', currencyCode:'SGD', currencySign: ""},
    sa: {countryName:'South Africa', currencyCode:'ZAR', currencySign: ""},
    th: {countryName:'Thailand', currencyCode:'BHT', currencySign: ""},
    en: {countryName:'USA (EN)', currencyCode:'USD', currencySign: ""},
    es: {countryName:'USA (ES)', currencyCode:'USD', currencySign: ""},
    ae: {countryName:'United Arab Emirates', currencyCode:'AED', currencySign: ""},
    uk: {countryName:'United Kingdom', currencyCode:'GPP', currencySign: ""},
    vn: {countryName:'Vietnam', currencyCode:'VND', currencySign: ""},
    flighthubdeal: {countryName:'United States',currencyCode:''}
};


export const countrySign = {
    USD: '$',
    CAD: 'C$',
    INR: '₹',
    AED: 'DH',
    AUD: '$',
    HKD: 'HK$',
    GBP: '£',
    THB: '฿',
    SGD: 'S$',
}