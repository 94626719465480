import React,{useState} from "react";
import moment from "moment";
import '../../app/css/style.css'
import { phoneNum } from "../../utils/globalVars";
import FlightBookForm from "../../components/flight-book-form/FlightBookForm.component";
import { getDuration } from "../../utils/utilFn";




const BookTicketsContainer = ({ data }) => {

  const[index, setIndex] =  useState(false)

  const handleAccordion =(e)=>{
    setIndex(e)
  }

  const getAirportNames = (iataCode) => {
    if (!!data.airportNames)
      return `${data.airportNames[iataCode].code} | ${data.airportNames[iataCode].name}, ${data.airportNames[iataCode].country}`;
  };
  const getAirlineName = (code) => data.allAirlineNames[code];

  const loadFullItinerary = (arr) => {
    return arr.map((el, index) => { 
      return (
        <div key={index} className="cm-iti-item">
          <h2>
            <i className="fa-solid fa-plane-departure"></i>{" "}
            {index === 0 ? "Departure" : "Return"}-{" "}
            {getAirportNames(el.segments[0].departure.iataCode)}
          </h2>
          {loadSegments(el.segments)}
        </div>
      );
    });
  };


  const loadSegments = (segmentArr) => {
    return segmentArr.map((el) => (
      
      <>
        <div key={el.id} className={`flight-detail ${index === el.id ? "active" : ""}`}>
          <div className="fl-inner-detail cm-flex" onClick={()=> handleAccordion(el.id)}>
            <div className="head-wid">
              <div className="dep-ret-info">{getAirlineName(el.carrierCode)} <span className="day">{moment(el.departure.at).format("DD MMM, hh")}</span> </div>
            </div>
            <div className="flht-details cm-flex">
              <div className="tim-sec">
                <span className="time">{moment(el.departure.at).format("hh:mm a")}</span>
                <span className="dest">{el.departure.iataCode}</span>
              </div>
              <div className="stop-view">
                <span className="mid-line"></span>
                <span className="stop">{el.numberOfStops} Stop(s)
                  {/* {el.segments.length > 1
                  ? `${el.segments.length - 1} Stop(s) `
                  : ""} */}
                </span>

              </div>
              <div className="tim-sec">
                <span className="time">{moment(el.arrival.at).format("hh:mm a")}</span>
                <span className="dest">{el.arrival.iataCode}</span>
              </div>
              <div className="air-travel">
                <span className="tol-time">{getDuration(el.duration)}</span>
              </div>
            </div>
          </div>
          <div className="flight-ful-detail">
          <div className="stop-view"><span className="mid-line"></span><span className="stop"></span></div>
            <div className="flight-des">
              <span className="flight-time">{moment(el.departure.at).format(" hh:mm A")}</span>
              <span>{getAirportNames(el.departure.iataCode)} Terminal:{" "} {el.departure.terminal}</span>
            </div>
            <div><span className="tol-time">{getDuration(el.duration)}</span></div>
            <div className="flight-des">
              <span className="flight-time">{moment(el.arrival.at).format(" hh:mm A")}</span>
              <span>{getAirportNames(el.arrival.iataCode)} Terminal:{" "} {el.arrival.terminal}</span>
            </div>
          </div>
        </div>
      </>
    ));
  };

  return (
    <div className="cm-book-ticket-container cm-section ticket_booking mt-z">
      <div className="cm-page-center cm-flex-type-1 cm-flex-align-fs">
        <div className="cm-left-col cm-lr-pad">
          <div className="cm-fl-res-iti-tab">
            {loadFullItinerary(data.flData.itineraries)}
          </div>
          <FlightBookForm
            travData={data.flData.travelerPricings}
           // grandTotal={data.flData.price.grandTotal}
           grandTotal = {data.grandTotal}
            flightTotal = {data.flightTotal}

          />
        </div>
        <div className="cm-right-col cm-lr-pad">
          <div className="cm-booking-cta cm-txt-center">
            <h4>Need Help?</h4>
            <div className="cm-content">
              <p>
                Our travel experts are just a call away! Get in touch now to
                customize your vacation hassle-free and enjoy your time away!
              </p>
              <h5>Call Us</h5>
              <a className="cm-sec-col2" href={`tel: ${phoneNum.value}`}>
                {phoneNum.label}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookTicketsContainer;
