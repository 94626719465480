import React from "react";
import moment from "moment";

import CtaType1 from "../../components/cta/CtaType1.component";
import CtaPopup from "../../components/cta-popup/CtaPopup.component";
import FlightResultItem from "../../components/flight-result-item/FlightResultItem.component";
import CarriersFilter from "../../components/carriers-filter/CarriersFilter.component";
import StopsFilter from "../../components/stops-filter/StopsFilter.component";
import { useNavigate } from "react-router-dom";
import { phoneNum } from "../../utils/globalVars";
import BannerSearchForm from "../../components/flight-search-form/BannerSearchForm.component";
import AirportFilter from "../../components/airport-filter/AirportFilter";
import FlLoader from "../../components/fl-loader/FlLoader.component";
import SwiperResult from "../../components/swiper-result/SwiperResult";
import HourFiltter from "../../components/time-filter/HourFiltter";
import bannerBg from "../../assets/images/packages/packages_banner.jpg";

const FlightResultContainer = ({ dataToSend, flData, travObj }) => {
  const [data, setData] = React.useState(null);
  //AirportFilter State
  const [selectedAirport, setSelectedAirport] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedHour, setSelectedHours] = React.useState("");

  ///testAirport
  const [selectedAirports, setSelectedAirports] = React.useState([]);

  const [airlieData, setAirlieData] = React.useState([]);
  const [sectorWiseData, setSectorWiseData] = React.useState([]);
  console.log("flData", flData);
  const navigate = useNavigate();
  const [filters, setFilters] = React.useState({
    stops: dataToSend.filters.stopsFilter,
    carriers: !!dataToSend.filters.carrierFilter
      ? dataToSend.filters.carrierFilter.split(",")
      : [],
  });

  console.log("pyush", airlieData);
  //console.log(dataToSend.locationDeparture+dataToSend.locationArrival);
  React.useEffect(() => {
    fetch("http://15.207.98.255/wp-json/wp/v3/flybirdtrips")
      .then((res) => res.json())
      .then((result) => setAirlieData(result));
  }, []);
  React.useEffect(() => {
    if (airlieData.length > 0) {
      //const dataToSearch = "jfksdq";
      const dataToSearch =
        dataToSend.locationDeparture.toLowerCase() +
        dataToSend.locationArrival.toLowerCase();
      const filterSectorData = airlieData.filter(
        (el) => el.sector === dataToSearch
      );
      //console.log("filterSectorData", filterSectorData)
      setSectorWiseData(filterSectorData);
    }
  }, [airlieData]);

  const loadflights = () => {
    return flData.data.data.map((el) => {
      const airlineName =
        flData.data.dictionaries.carriers[el.validatingAirlineCodes[0]];
      let flightPrice = {};
      const carriesrObj = flData.data.dictionaries.carriers;
      //const filteredData = sectorWiseData.filter(el=>el.airlines===airlineName.toLowerCase());
      for (let key in carriesrObj) {
        const filteredData = sectorWiseData.filter(
          (el) => el.airlines.toLowerCase() === key.toLowerCase()
        );
        //console.log('filteredData',filteredData,key);

        flightPrice[key] =
          filteredData.length > 0 ? filteredData[0].price : null;
      }
      //console.log("flightPrice",flightPrice);
      return (
        <FlightResultItem
          flData={el}
          key={el.id}
          airlineName={airlineName}
          airportNames={flData.data.airportNames}
          allAirlineNames={flData.data.dictionaries.carriers}
          navigate={navigate}
          flightPrice={flightPrice}
        />
      );
    });
  };

  const handleStopChange = (val) => {
    handleFilters({
      ...filters,
      stops: filters.stops !== val ? val : null,
    });

    setFilters({
      ...filters,
      stops: filters.stops !== val ? val : null,
    });
  };

  const handleCarrierChange = (val) => {
    console.log("Selected Airports:", val);
    const isPresent = filters.carriers.includes(val);
    let newCarrArr = [];
    if (isPresent) {
      newCarrArr = filters.carriers.filter((el) => el !== val);
    } else {
      newCarrArr = [...filters.carriers, val];
    }

    handleFilters({
      ...filters,
      carriers: newCarrArr,
    });

    setFilters({
      ...filters,
      carriers: newCarrArr,
    });
  };

  //Hourly dropdown

  const handleOptionSelect = (selectedValue) => {
    if (selectedValue != "0") {
      setSelectedHours(selectedValue);
      setIsLoading(true);
      flData.data.data = flData.data.data.filter(
        (x) =>
          x.itineraries[0].duration
            .split("PT")[1]
            .substring(
              0,
              x.itineraries[0].duration.split("PT")[1].length - 4
            ) == selectedValue
      );

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      return flData.data.data;
    }
  };

  //AirportFiltter
  const handleAirportChange = (value) => {
    const iataCode = value;
    if (value) {
      setSelectedAirport(iataCode);
      setIsLoading(true);
      flData.data.data = flData.data.data.filter(
        (x) => x.itineraries[0].segments[0].departure.iataCode == value
      );
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } else {
      setSelectedAirport("");
      return flData.data.data;
    }
  };

  const handleFilters = (filters) => {
    const filterString = `${
      !!filters.stops ? `&stopsFil=${filters.stops}` : ""
    }${
      filters.carriers.length > 0 ? `&carFil=${filters.carriers.join()}` : ""
    }`;

    navigate({
      pathname: "/flights",
      search: `?search_t=${moment().unix()}&tripType=${
        dataToSend.tripType
      }&dep_loc=${dataToSend.locationDeparture}&dest_loc=${
        dataToSend.locationArrival
      }&dep_dt=${dataToSend.departure}&ret_dt=${dataToSend.arrival}&fl_cl=${
        dataToSend.flightClass
      }&adt=${travObj.adults}&chd=${travObj.child}${filterString}`,
    });

    console.log("filterString", filterString);
  };

 
    ///In mobile Open filtter Modal
  const [activeModalId, setActiveModalId] = React.useState(null);

  const handleModalOpen = (modalId) => {
    setActiveModalId(modalId);
  };

  const handleModalClose = () => {
    setActiveModalId(null);
  };
  const Modal = ({ isOpen, onClose, modalId }) => {
    return (
      isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <span className="done-btn" onClick={() => onClose(modalId)}>
              Done
            </span>
            <span className="close-bttn" onClick={() => onClose(modalId)}>
              <i className="fa-solid fa-circle-xmark"></i>
            </span>
            <div>
              {modalId == 1 ? (
                <StopsFilter
                  selected={filters.stops}
                  handleStopChange={handleStopChange}
                />
              ) : (
                ""
              )}
              {modalId == 2 ? (
                <CarriersFilter
                  selected={filters.carriers}
                  handleCarrierChange={handleCarrierChange}
                  data={flData.data.dictionaries.carriers}
                />
              ) : (
                ""
              )}

              {modalId == 3 ? (
                <HourFiltter
                  data={flData.data.data}
                  onSelect={handleOptionSelect}
                />
              ) : (
                ""
              )}
              {modalId == 4 ? (
                <AirportFilter
                  selected={selectedAirport}
                  onInputChange={handleAirportChange}
                  data={flData.data}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )
    );
  };

  // Form Modal

  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
  const [isOpenModal, setOpenModal] = React.useState(false); // Define your breakpoint for mobile

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFormModalOpen = () => {
    setOpenModal(true);
  };
  const handleFormModalClose = () => {
    setOpenModal(false);
  };

  const FormModal = ({ isOpen, onClose }) => {
    return (
      isOpen && (
        <div className="form-modal-overlay">
          <div className="form-modal-content">
            <span className="close-btn" onClick={onClose}>
              &times;
            </span>
            <div>
              <BannerSearchForm depVal={data} urlVal={dataToSend} />
            </div>
          </div>
        </div>
      )
    );
  };


  return (
    <React.Fragment>
      {!isLoading ? (
        <div className="cm-flight-result-container ticket_booking">
          <div
            className="cm-banner-search-container cm-bg-prop cm-section cm-pos-relative"
            style={{
              backgroundImage: `url("${bannerBg}")`,
              backgroundPosition: "bottom",
            }}
          >
            <BannerSearchForm depVal={data} urlVal={dataToSend} />
          </div>

          {flData.data.data.length > 0 ? (
             <React.Fragment>
             {/* <CtaType1 fareToShow={flData.data.data[0].price.base} /> */}

             <div className="cm-section new-cm-section cm-flight-result-wrapper">
               <div className="cm-page-center cm-flex cm-flex-align-fs">
                 <div className="cm-filter-sidebar cm-lr-pad">
                   <div className="inner-sidebar">
                     <div className="sidebar-wrapper">
                       <StopsFilter
                         selected={filters.stops}
                         handleStopChange={handleStopChange}
                       />
                       <CarriersFilter
                         selected={filters.carriers}
                         handleCarrierChange={handleCarrierChange}
                         data={flData.data.dictionaries.carriers}
                       />
                       <HourFiltter
                         data={flData.data.data}
                         onSelect={handleOptionSelect}
                       />

                       <AirportFilter
                         selected={selectedAirport}
                         // selected={filters.airportNames}
                         // handleAirpotChange={handleAirpotChange}
                         onInputChange={handleAirportChange}
                         data={flData.data}
                       />
                     </div>
                     <div className="mobile-tabs">
                       <div className="tabs-header">
                         <p>Filter By:</p>
                         <button onClick={() => handleModalOpen(1)}>
                           Stops
                         </button>
                         <button onClick={() => handleModalOpen(2)}>
                           Airlines
                         </button>
                         <button onClick={() => handleModalOpen(3)}>
                           Hour
                         </button>
                         <button onClick={() => handleModalOpen(4)}>
                           Airports
                         </button>
                         <Modal
                           isOpen={activeModalId === 1}
                           onClose={handleModalClose}
                           modalId={1}
                         />
                         <Modal
                           isOpen={activeModalId === 2}
                           onClose={handleModalClose}
                           modalId={2}
                         />
                         <Modal
                           isOpen={activeModalId === 3}
                           onClose={handleModalClose}
                           modalId={3}
                         />
                         <Modal
                           isOpen={activeModalId === 4}
                           onClose={handleModalClose}
                           modalId={4}
                         />
                       </div>
                       
                     </div>
                   </div>
                   <div className="social-media-add">
                     <a
                       href="https://www.facebook.com/flybirdtrips"
                       target="_blank"
                     >
                       <img src="./images/add_new.jpg" />
                     </a>
                     <a href="https://twitter.com/flybirdtrips" target="_blank">
                       <img src="./images/add_new2.jpg" />
                     </a>
                   </div>
                 </div>
                 <div className="cm-result-wrap cm-lr-pad">
                   <SwiperResult
                     selected={filters.stops}
                     handleStopChange={handleStopChange}
                     data={flData.data.dictionaries.carriers}
                     allData={flData.data}
                     dictionaries={flData.data.dictionaries}
                   />
                   {loadflights()}
                 </div>
                 <div className="add-col">
                   <a href={`tel:${phoneNum.value}`}>
                     <img src="./images/add-new3.jpg" />
                   </a>
                 </div>
               </div>
             </div>
             <CtaPopup dataToSend={dataToSend} />
           </React.Fragment>
          ) : (
            <div className="cm-empty-fl-container cm-section cm-txt-center">
              <p className="cm-empty-msg">No flights available.</p>
              <button
                className="cm-btn cm-sec-bg2 cm-white-col"
                onClick={() => navigate("/")}
              >
                Go back
              </button>
            </div>
          )}
        </div>
      ) : (
        <FlLoader data={dataToSend} />
      )}
    </React.Fragment>
  );
};

export default FlightResultContainer;
