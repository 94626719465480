
import React,{useState, useEffect} from "react";


const MetaPage = ()=>{
    const [data, setData] = useState('');
    const [name, setName] = useState("");
    const [sector, setSector] = useState("");
    const [price, setPrice] = useState("");
    const [dateRange, setDateRange] = useState("");
    

    return(
        <>
            <div className="meta-section ticket_booking">
                <div className="cm-page-center cm-lr-pad">
                    <form>
                    {/* {
                        data.map((ele)=>{
                            return(
                                <>
                                    <div className="form-group">
                                        <label>Airlines</label>
                                        <input type="text" value={ele.airlines} onChange={(e) => setName(e.target.value)} placeholder="Airlines" className="form-control"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Sectors</label>
                                        <input type="text" name="sector" value={ele.sector} onChange={(e) => setSector(e.target.value)} placeholder="JFQSDQ" className="form-control"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Price %</label>
                                        <input type="text" name="price" value={ele.price} onChange={(e) => setPrice(e.target.value)} placeholder="Price" className="form-control"/>
                                    </div>
                                    <div className="form-group">
                                        <label>Date Range</label>
                                        <input type="text" name="dateRange" value={ele.datarange} onChange={(e) => setDateRange(e.target.value)} placeholder="1-15 june" className="form-control"/>
                                    </div>
                                </>
                            )
                        })  
                        } */}
                        <div className="add-btn full-width">
                            <button type="submit" className="btn">Add</button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </>
    )
}
export default MetaPage;