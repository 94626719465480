import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./Header.styles.css";
// import logo from "../../assets/images/flight/flybirdtripsTm.png";
import support from "../../assets/images/flight/support (1).png";
import direction from "../../assets/images/flight/direction.png";
import { phoneNum } from "../../utils/globalVars";
import { $CombinedState } from "redux";

const Header = () => {
  const [toggle, setToggle] = useState(false);
  const toggleMenu = () => {
      if(toggle===false){
        setToggle(true);
      }else{
        setToggle(false)
      }
  }
  // $(document).ready(function () {
  //   $('.toggle').click(function () {
  //     $('.menu-list').slideToggle()
  //     $(this).toggleClass('active')
  //   })
  // });

  return (
    <>
      {/* <header>
        <div classNameName="container">
          <div classNameName="flex align-center space-between">
            <div classNameName="site-logo" style={{ width: "150px" }}>
              <Link to="/">
                <img src={logo} alt="Fly Bird Trips" />
              </Link>
            </div>
            <nav>
              <div classNameName="menu">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/packages">Packages</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  <li>
                    <a href={`tel:${phoneNum.label}`}>
                      <span>
                        <i classNameName="fa fa-headphones" aria-hidden="true"></i>
                      </span>
                      Support
                    </a>
                  </li>
                  <li>
                    <a href={`tel:${phoneNum.label}`}>
                      <span>
                        <i classNameName="fa fa-phone" aria-hidden="true"></i>
                      </span>
                      {phoneNum.label}
                    </a>
                  </li>
                </ul>
              </div>
              <div classNameName="mobile-view">
                <div classNameName="contact-no">
                  <a href={`tel:${phoneNum.label}`}>
                    <span>
                      <i classNameName="fa fa-phone" aria-hidden="true"></i>
                    </span>
                    {phoneNum.label}
                  </a>
                </div>
              </div>
              <div classNameName="toggle">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </nav>
          </div>
        </div>
  </header>*/}

      <header>
        <div className="header-top-area">
          <div className="cm-page-center">
            <div className="row">
              <div className="header-top-left">
                <div className="col-md-9 col-sm-9 col-xs-12">
                  <ul className="header-top-contact">
                    <li>
                      <i className="fa fa-location-dot" aria-hidden="true"></i>
                      30 N Gould St Ste R Sheridan, WY 82801
                    </li>
                    <li>
                      <i className="fa fa-phone" aria-hidden="true"></i>
                      <a href={`tel:${phoneNum.value}`}>{phoneNum.label}</a>
                      
                    </li>
                    <li>
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                      info@flybirdtrips.com
                    </li>
                  </ul>
                </div>
              </div>
              <div className="header-top-right text-right">
                <div className="col-md-3 col-xs-12 book-tab">
                  <div className="book-btn">
                    <a href="#">Book Online</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-bottom-area  " id="stick-header">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-2 col-xs-12 tap-v-responsive">
                <div className="logo-area">
                  <a href="/">
                    <img className="logo-img"
                      src={require("../../assets/images/home/logo2.png")}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="col-md-10">
                <nav className={`navBarmenu ${(toggle===false) ? ``: `active`}`}>
                  <ul className="main-menu text-right">
                    <li>
                      <Link to="/">Home</Link>
                    </li> 
                    <li>
                      <Link to="/packages" onClick={() => toggleMenu()}>Packages</Link>
                    </li>
                    <li>
                      <Link to="/about-us" onClick={() => toggleMenu()}>About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact-us" onClick={() => toggleMenu()}>Contact Us</Link>
                    </li>
                    <li>
                      <a href={`tel:${phoneNum.value}`}>
                        <span>
                          <i
                            className="fa fa-headphones"
                            aria-hidden="true"
                          ></i>
                        </span>
                        Support
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="toggle-menu" onClick={() => toggleMenu()}>
                <i class="fa-solid fa-bars"></i>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
