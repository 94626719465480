import React from "react";
import Lottie from "react-lottie";
import moment from "moment";
import animationData from "../../assets/data/loader.json";
import bannerBg from "../../assets/images/packages/packages_banner.jpg";
import "./FlLoader.styles.css";

export default function FlLoader({ data }) {
  // console.log("data", data);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="cm-section cm-fl-loader-container fl_loder ">
      <div
        className="cm-banner-search-container cm-bg-prop cm-section cm-pos-relative"
        style={{
          backgroundImage: `url("${bannerBg}")`,
          backgroundPosition: "bottom",
        }}
      >
        <div className="cm-page-center cm-pos-relative cm-lr-pad">
          <div className="cm-banner-content">
            {/* <h1 className="cm-white-col">Disclaimer</h1> */}
          </div>
        </div>
      </div>
      <div className="cm-page-center cm-txt-center">
        <div className="cm-fl-loader-wrapper box-shadow-2">
          <h4>Fetching best deals for you....</h4>
          <div className="earth">
            <img
              src={require("../../assets/images/flight/Airplane.png")}
              alt=""
            />
          </div>
          {/*<Lottie options={defaultOptions} height={160} width={160} />*/}
          <h3 className="cm-section-sh">
            <i className="fa-solid fa-plane-departure"></i> From{" "}
            {data.locationDeparture} to {data.locationArrival}
          </h3>
          <p>
            <i className="fa-solid fa-clock"></i>{" "}
            {moment(data.departure).format("DD MMMM, YYYY")}{" "}
            {data.tripType === "round-trip"
              ? `- 
            ${moment(data.arrival).format("DD MMMM, YYYY")}`
              : ""}
          </p>
        </div>
      </div>
    </div>
  );
}
